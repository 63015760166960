import dayjs from 'dayjs'
import { isBefore, isAfter, isEqual } from 'date-fns'
import { formatFloatNumber } from '~/services/number'

export const isDateBefore = (date: Date, dateToCompare: Date) => {
  return isBefore(
    new Date(date).setHours(0, 0, 0, 0),
    new Date(dateToCompare).setHours(0, 0, 0, 0)
  )
}

export const isDateBeforeOrEqual = (date: Date, dateToCompare: Date) => {
  const standardDate = new Date(date).setHours(0, 0, 0, 0)
  const standardDateToCompare = new Date(dateToCompare).setHours(0, 0, 0, 0)

  return (
    isBefore(standardDate, standardDateToCompare) ||
    isEqual(standardDate, standardDateToCompare)
  )
}

export const compareDates = (date1: Date | null, date2: Date | null) => {
  if (date1 === null) {
    return 1
  }

  if (date2 === null) {
    return -1
  }

  return isAfter(date1, date2) ? -1 : 1
}

export const isDateAfter = (date: Date, dateToCompare: Date) => {
  return isAfter(
    new Date(date).setHours(0, 0, 0, 0),
    new Date(dateToCompare).setHours(0, 0, 0, 0)
  )
}

export const isDateAfterOrEqual = (date: Date, dateToCompare: Date) => {
  const standardDate = new Date(date).setHours(0, 0, 0, 0)
  const standardDateToCompare = new Date(dateToCompare).setHours(0, 0, 0, 0)

  return (
    isAfter(standardDate, standardDateToCompare) ||
    isEqual(standardDate, standardDateToCompare)
  )
}

export const isDateValid = (date: string) => {
  return !isNaN(Date.parse(date))
}

export const formatDate = (date: string | Date) => {
  const newDate = new Date(date)
  let day = newDate.getDate().toString()
  if (day.length === 1) {
    day = '0' + day
  }

  let month = (newDate.getMonth() + 1).toString()
  if (month.length === 1) {
    month = '0' + month
  }

  const year = newDate.getFullYear()

  return `${day}/${month}/${year}`
}

export const formatDate2 = (date: string | Date) => {
  const newDate = new Date(date)
  let day = newDate.getDate().toString()
  if (day.length === 1) {
    day = '0' + day
  }

  let month = (newDate.getMonth() + 1).toString()
  if (month.length === 1) {
    month = '0' + month
  }

  const year = newDate.getFullYear()

  return `${year}-${month}-${day}`
}

export const formatDateRange2 = (dateRange: [Date, Date]): [string, string] => {
  return [formatDate2(dateRange[0]), formatDate2(dateRange[1])]
}

export const getMonth = (month: number) => {
  return `date.months.${month}`
}

export const getShortMonth = (month: number) => {
  return `date.shortmonths.${month}`
}

export const getDayOfTheWeek = (day: number) => {
  return `date.days.${day}`
}

export const getQuarter = (quarter: number) => {
  return `date.quarters.${quarter}`
}

export const getShortYear = (year: number) => {
  return year.toString().substring(2, 4)
}

export const transformDateToTime = (date: Date) => {
  const hours = date.getHours()
  const minutes = date.getMinutes()

  const hoursText =
    hours.toString().length === 1 ? `0${hours.toString()}` : hours.toString()

  const minutesText =
    minutes.toString().length === 1
      ? `0${minutes.toString()}`
      : minutes.toString()

  const time = hoursText + ':' + minutesText

  return time
}

export const yearsToYearsMonthsDays = (value: number) => {
  const totalDays = value > 0 ? value * 365 : 0
  // TODO: This method does not work if totalDays = 364 it will return 12 months and 4 days, and totalDays = 365 will return 1 year

  const years = Math.floor(totalDays / 365)
  const months = Math.floor((totalDays - years * 365) / 30)
  const days = totalDays - years * 365 - months * 30
  const formattedDays = formatFloatNumber(days, 2, 2)

  return { years, months, days: formattedDays }
}

export const getDateMinusDays = (date: Date, diffDays: number) =>
  new Date(new Date(date).setDate(date.getDate() - diffDays))

export const getDateMinusMonth = (date: Date, diffMonths: number) =>
  new Date(new Date(date).setMonth(date.getMonth() - diffMonths))

export const getDateMinusYear = (date: Date, diffYears: number) =>
  new Date(new Date(date).setFullYear(date.getFullYear() - diffYears))

export const getFormattedTimeSinceDate = (date: Date) => {
  const { $t } = useNuxtApp()
  const dateNow = dayjs()
  const dateToCompare = dayjs(date)
  let time = null

  if (dateNow.diff(dateToCompare, 'year') >= 1) {
    time = `${Math.round(dateNow.diff(dateToCompare, 'year'))} ${$t(
      'date.text.years',
      { count: Math.round(dateNow.diff(dateToCompare, 'year')) }
    )}`
  } else if (dateNow.diff(dateToCompare, 'month') >= 1) {
    time = `${Math.round(dateNow.diff(dateToCompare, 'month'))} ${$t(
      'date.text.months',
      { count: Math.round(dateNow.diff(dateToCompare, 'month')) }
    )}`
  } else if (dateNow.diff(dateToCompare, 'week') >= 1) {
    time = `${Math.round(dateNow.diff(dateToCompare, 'week'))} ${$t(
      'date.text.weeks',
      { count: Math.round(dateNow.diff(dateToCompare, 'week')) }
    )}`
  } else if (dateNow.diff(dateToCompare, 'day') >= 1) {
    time = `${Math.round(dateNow.diff(dateToCompare, 'day'))} ${$t(
      'date.text.days',
      { count: Math.round(dateNow.diff(dateToCompare, 'day')) }
    )}`
  } else {
    return $t('date.text.today')
  }

  return $t('date.text.timeAgo', { time })
}
